import {axios} from "./AxiosInstance";
import { TOKEN_KEY_STORAGE } from "./constants";

export function authHeader() {
  try {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      return token;
    }
  } catch (err) {
    localStorage.removeItem("token");
    return null;
  }
}

export function setAuthToken(token) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token.access_token;
  }
}

export function authHeaderParams() {
  return {
    Authorization: "Bearer " + authHeader(),
  };
}
