import React from "react";

const TrackingStatusBox = ({ statusData, handleDrag, handleDrop }) => {
  return (
    <div
      className={
        "card " + (statusData.order !== null ? "bg-dark" : "bg-danger")
      }
      draggable={true}
      id={statusData.gb_status_id}
      onDragOver={(ev) => ev.preventDefault()}
      onDragStart={handleDrag}
      onDrop={handleDrop}
      style={{
        color: "#FFF",
        width: "100px",
        height: "auto",
        margin: "10px",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      {statusData.status}
    </div>
  );
};

export default TrackingStatusBox;
