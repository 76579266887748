import { Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { consoleLogger } from "../../../helpers/ConsoleLogger";
import trackingStatusService from "../../../services/TrackingModuleServices/trackingStatus.service";
import TrackingStatusBox from "./TrackingStatusBox";

const TrackingStatusDnD = ({ statuses }) => {
  const [dragId, setDragId] = useState();
  const [boxes, setBoxes] = useState();
  const [stillSaving, setStillSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadStatuses = async () => {
      const result = await trackingStatusService.getAllStatuses();
      setBoxes(result.data);
      setLoading(false);
    };
    loadStatuses();
  }, []);

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    if (
      ev.currentTarget.id !== "withOrder" &&
      ev.currentTarget.id != "withoutOrder"
    ) {
      const dragBox = boxes.find((box) => box.gb_status_id == dragId);
      const dropBox = boxes.find(
        (box) => box.gb_status_id == ev.currentTarget.id
      );
      const dragBoxOrder = dragBox.order;
      const dropBoxOrder = dropBox.order;

      const newBoxState = boxes.map((box) => {
        if (box.gb_status_id == dragId) {
          box.order = dropBoxOrder;
        }
        if (box.gb_status_id == ev.currentTarget.id) {
          box.order = dragBoxOrder;
        }
        return box;
      });
      setBoxes(newBoxState);
    } else {
      if (ev.currentTarget.id == "withoutOrder") {
        const newBoxState = boxes.map((box) => {
          if (box.gb_status_id == dragId) {
            box.order = null;
          }
          return box;
        });
        setBoxes(newBoxState);
      }

      if (ev.currentTarget.id == "withOrder") {
        const maxValueOfOrder = Math.max(...boxes.map((box) => box.order), 0);
        const newBoxState = boxes.map((box) => {
          if (box.gb_status_id == dragId) {
            box.order = parseInt(maxValueOfOrder) + 1;
          }
          return box;
        });
        setBoxes(newBoxState);
      }
    }
  };

  const handleSaveOrder = async () => {
    const result = await trackingStatusService.saveStatusesOrder(boxes);
    consoleLogger(result);
  };

  if (loading) {
    return "Cargando...";
  }

  return (
    <>
      <div className="d-flex flex-row">
        <div className="d-flex flex-column col-6">
          <div
            className="d-flex flex-column flex-md-row justify-content-center mt-3"
            style={{ border: "2px dashed gray", borderRadius: "5px" }}
            id="withOrder"
            onDrop={handleDrop}
            onDragOver={(ev) => ev.preventDefault()}
          >
            <p className="text-dark p-4  m-auto">
              Arroje aquí los estados que tienen un orden en especifico,
              recuerde que este es el orden en el que se le mostrará al cliente.
            </p>
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-center flex-wrap">
            {boxes
              .sort((a, b) => a.order - b.order)
              .map((box) => {
                if (box.order !== null) {
                  return (
                    <TrackingStatusBox
                      key={box.gb_status_id}
                      statusData={box}
                      handleDrag={handleDrag}
                      handleDrop={handleDrop}
                    />
                  );
                }
              })}
          </div>
        </div>
        <div className="d-flex flex-column col-6">
          <div
            className="d-flex flex-column flex-md-row justify-content-center mt-3"
            style={{ border: "2px dashed red", borderRadius: "5px" }}
            id="withoutOrder"
            onDrop={handleDrop}
            onDragOver={(ev) => ev.preventDefault()}
          >
            <p className="text-danger p-4 m-auto">
              Arroje aquí los estados que no tendrán un orden especifico (Ej:
              Anulada, etc)
            </p>
          </div>

          <div className="d-flex flex-column flex-md-row justify-content-center flex-wrap">
            {boxes
              .sort((a, b) => a.order - b.order)
              .map((box) => {
                if (box.order === null) {
                  return (
                    <TrackingStatusBox
                      key={box.gb_status_id}
                      statusData={box}
                      handleDrag={handleDrag}
                      handleDrop={handleDrop}
                    />
                  );
                }
              })}
          </div>
        </div>
      </div>
      <Button
        color="primary"
        variant="contained"
        className="mt-4"
        disabled={stillSaving ? true : false}
        onClick={handleSaveOrder}
      >
        {stillSaving ? "Espere por favor..." : "Guardar Orden de estados"}
      </Button>
    </>
  );
};

export default TrackingStatusDnD;
