import { axios } from "../helpers/AxiosInstance";
import { consoleLogger } from "../helpers/ConsoleLogger";
import { LOGIN_FAIL, LOGIN_SUCCESS, USER_LOADED, AUTH_ERROR, LOGOUT } from "./types";

export const login = ({ username, password }) => async (dispatch) => {
  try {
    const userData = {
      username: username,
      password: password,
    };
    const result = await axios.post("login", userData);
    consoleLogger('Logeando REDUX')
    dispatch({
      type: LOGIN_SUCCESS,
      payload: result.data,
    });
    loadUser();
  } catch (err) {
    const error = err.response.data;
    consoleLogger(error);
    dispatch({
      type: LOGIN_FAIL,
    });

  }
};

export const loadUser = () => async (dispatch) => {
  try {
    const result = await axios.get("me");
    dispatch({
      type: USER_LOADED,
      payload: result.data,
    });
  } catch (err) {
    consoleLogger(err);

    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const isAuth = () => async (dispatch) => {
  try {
    const result = await axios.get("isAuth");
    return result;
  } catch (err) {
    consoleLogger(err);

    dispatch({
      type: AUTH_ERROR,
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: LOGOUT,
    });
  } catch (err) {
    consoleLogger(err);
  }
};