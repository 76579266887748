import React from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const MyMaterialButton = ({
  label,
  color,
  icon,
  variant,
  size,
  children,
  ...props
}) => {
  let startIcon;

  if (icon) {
    if (icon === "Add") {
      startIcon = <AddIcon fontSize="large" />;
    }
  }

  return (
    <Button
      className="m-1"
      variant={variant || "contained"}
      color={color || "primary"}
      size={size || "medium"}
      startIcon={startIcon}
      {...props}
    >
      {children || label}
    </Button>
  );
};

export default MyMaterialButton;
