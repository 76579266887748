import React from "react";
import MaterialTable, { MTableCell } from "material-table";
import { Paper } from "@material-ui/core";
import dayjs from "dayjs";
import trackingStatusService from "../../../services/TrackingModuleServices/trackingStatus.service";

export const TrackingStatusesTable = React.forwardRef(
  ({ setShowData, setFormShow }, ref) => {
    return (
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
          Cell: (props) => <MTableCell {...props} align="center" />,
        }}
        title="Estados de ratreo"
        tableRef={ref}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count} páginas",
          },
          toolbar: {
            nRowsSelected: "{0} filas(s) seleccionadas",
            searchPlaceholder: "Estado",
            searchTooltip: "Buscar",
          },
          header: {
            actions: "Acciones",
          },
          pagination: {
            labelRowsSelect: "filas",
          },
          body: {
            emptyDataSourceMessage: "No hay registros para mostrar",
            filterRow: {
              filterTooltip: "Filtro",
            },
          },
        }}
        columns={[
          {
            title: "Estado",
            field: "status",
          },
          {
            title: "Descripción",
            field: "description",
          },
          {
            title: "Activo",
            field: "isactive",
            render: ({ isactive, ...rest }) =>
              isactive === "Y" ? (
                <span class="badge badge-pill badge-success">Activo</span>
              ) : (
                <span class="badge badge-pill badge-danger">Inactivo</span>
              ),
          },
          {
            title: "Fecha de registro",
            field: "created",
            render: (rowData) => dayjs(rowData.created).format("DD/MM/YYYY"),
          },
        ]}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF",
            padding: "20px",
          },
          rowStyle: {
            backgroundColor: "#EEE",
          },
        }}
        data={(query) =>
          new Promise(async (resolve, reject) => {
            const result = await trackingStatusService.getAllStatusesPaginated({
              page: query.page + 1,
              limit: query.pageSize,
              search: query.search,
            });

            resolve({
              page: parseInt(result.meta.current_page - 1),
              data: result.data,
              totalCount: parseInt(result.meta.total),
            });
          })
        }
        actions={[
          {
            icon: "refresh",
            tooltip: "Refrescar datos",
            isFreeAction: true,
            onClick: () => ref.current && ref.current.onQueryChange(),
          },
          {
            icon: "edit",
            tooltip: "Editar Información",
            onClick: (event, rowData) => {
              setShowData(rowData);
              setFormShow(true);
            },
          },
        ]}
      />
    );
  }
);
