import React, { useEffect } from "react";
import { useState } from "react";
import { UnmountClosed } from "react-collapse";
import Spinner from "../../Spinner/Spinner";
import createAlert from "../../../helpers/createAlert";
import CreateTrackingForm from "../../TrackingModule/CreateTrackingForm";
import { TrackingTable } from "../../TrackingModule/TrackingTable";
import CreateClientForm from "./CreateClientForm";
import { ClientsTable } from "./ClientsTable";
import Button from "../../Button/Button";

const CreateClient = () => {
  const [requestStatus, setRequestStatus] = useState({
    message: "",
    success: false,
    sent: false,
  });
  const tableRef = React.createRef();
  const [showForm, setFormShow] = useState(false);
  const [showData, setShowData] = useState();

  const handleButtons = (e, type) => {
    e.preventDefault();
    setShowData();
    setFormShow(type === "close" ? false : true);
  };

  /*  useEffect(() => {
    const loadStatuses = async () => {
      const result = await trackingStatusService.getAllStatuses();
      setStatuses(result.data);
      setLoading(false);
    };
    loadStatuses();
  }, []);

  if (loading) {
    return <Spinner></Spinner>;
  } */

  return (
    <>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Módulo de clientes</h1>
      </div>

      <UnmountClosed isOpened={showForm}>
        <div className="row">
          <div className="col-auto mx-auto pb-4">
            <div className="card shadow">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary float-left">
                  Formulario de cliente
                </h6>
                <i
                  className="fas fa-times float-right"
                  onClick={(e) => handleButtons(e, "close")}
                  style={{ fontSize: "20px", color: "red" }}
                ></i>
              </div>
              <div className="card-body">
                <CreateClientForm
                  data={showData}
                  tableRef={tableRef}
                ></CreateClientForm>
              </div>
            </div>
          </div>
        </div>
      </UnmountClosed>

      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Clientes</h6>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-12 mx-auto">
              <Button
                icon="Add"
                label={"Agregar nuevo cliente"}
                onClick={(e) => handleButtons(e, "open")}
              />
            </div>
            <div className="col-12 mx-auto">{createAlert(requestStatus)}</div>
            <div className="col-12 mx-auto">
              <ClientsTable
                ref={tableRef}
                setShowData={setShowData}
                setFormShow={setFormShow}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateClient;
