import React from "react";
import MaterialTable, { MTableCell } from "material-table";
import { Paper } from "@material-ui/core";
import ProvidersService from "./Providers.service";
import {
  localization,
  optionsTable,
  tableIcons,
} from "../../../helpers/TableHelpers";

const ProviderTypesTable = React.forwardRef(
  ({ setShowData, setFormShow }, ref) => {
    return (
      <MaterialTable
        icons={tableIcons}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
          Cell: (props) => <MTableCell {...props} align="center" />,
        }}
        title="Tipos de proveedores registrados"
        tableRef={ref}
        localization={localization("Nombre, descripción")}
        columns={[
          {
            title: "Nombre",
            field: "name",
          },
          {
            title: "Descripción",
            field: "description",
          },
          {
            title: "Activo",
            field: "isactive",
            render: (rowData) => (rowData.isactive === "Y" ? "Si" : "No"),
          },
        ]}
        options={optionsTable}
        data={(query) =>
          new Promise(async (resolve, reject) => {
            const result = await ProvidersService.getAllCompanyCategoriesPaginated(
              {
                page: query.page + 1,
                limit: query.pageSize,
                search: query.search,
              }
            );

            resolve({
              page: parseInt(result.meta.current_page - 1),
              data: result.data,
              totalCount: parseInt(result.meta.total),
            });
          })
        }
        actions={[
          {
            icon: "refresh",
            tooltip: "Refrescar datos",
            isFreeAction: true,
            onClick: () => ref.current && ref.current.onQueryChange(),
          },
          {
            icon: "edit",
            tooltip: "Editar Información",
            onClick: (event, rowData) => {
              setShowData(rowData);
              setFormShow(true);
            },
          },
        ]}
      />
    );
  }
);

export default ProviderTypesTable;
