import React, { useEffect, useState } from "react";
import ClientTopBar from "../../../components/Navbars/AdminPanelTopbar";
import LogoutModal from "../../../components/Modals/LogoutModal";
import ClientPanelSidebar from "../../../components/Navbars/AdminPanelSidebar";
import { Helmet } from "react-helmet";
import Spinner from "../../../components/Spinner/Spinner";
import $ from "jquery";
import "../../../assets/scss/sb-admin-2.scss";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.js";

const AdminPanelLayout = ({ children }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        // Getting pending messages number to show it in sidebar
        /*    const pendingMessages = await messagesService.getPendingMessagesCount();

        // Getting pending requests number to show it in sidebar
        const pendingFactibilityRequests = await factibilityService.pendingFactibilityRequestsCount();

        setData({
          pendingFactibilityRequests: pendingFactibilityRequests || 0,
          pendingMessages: pendingMessages || 0,
        });
        setLoading(!loading); */
      } catch (err) {}
    };
    /*     getData();
     */
  }, []);

  if (!loading) {
    return (
      <>
        <Helmet>
          <title>Panel de Sistema Impro</title>
          <meta charSet="utf-8" />
        </Helmet>
        <div id="wrapper">
          <ClientPanelSidebar pendingMessages={"3"} pendingRequests={"3"} />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <ClientTopBar userInfo={{ name: "Username" }} />
              <div className="container-fluid mx-auto">{children}</div>
            </div>
          </div>
        </div>
        <a class="scroll-to-top rounded" href="#">
          <i class="fas fa-angle-up"></i>
        </a>
        <LogoutModal></LogoutModal>
      </>
    );
  } else {
    return <Spinner></Spinner>;
  }
};

export default AdminPanelLayout;
