import { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Whitelogo from "../../assets/images/logos/logo-blanco.png";
import WhiteLogoShort from "../../assets/images/logos/logo-blanco-corto.png";

const StyledUl = styled.ul`
  background-color: #243147;
`;

const ClientPanelSidebar = ({ pendingMessages, pendingRequests }) => {
  useEffect(() => {
    const fnmap = {
      toggle: "toggle",
      show: "add",
      hide: "remove",
    };
    const collapse = (selector, cmd) => {
      const targets = Array.from(document.querySelectorAll(selector));
      targets.forEach((target) => {
        target.classList[fnmap[cmd]]("show");
      });
    };

    const toggleSidebarOnClick = (e) => {
      document.querySelector("body").classList.toggle("sidebar-toggled");
      document.querySelector(".sidebar").classList.toggle("toggled");
      if (document.querySelector(".sidebar").classList.contains("toggled")) {
        const elm = e.target;
        const selector = elm.getAttribute("data-target");
        collapse(selector, "toggle");
      }
    };

    const toggleScrollIcon = () => {
      var scrollDistance = window.scrollY;
      const scrollToTopIcon = document.querySelector(".scroll-to-top");
      if (scrollDistance > 100) {
        scrollToTopIcon.classList.add("fadeIn");
      } else {
        scrollToTopIcon.classList.remove("fadeIn");
      }
    };
    const toggleSidebarOnResize = () => {
      if (window.innerWidth < 768) {
        collapse(".sidebar .collapse", "toggle");
      }
      if (
        window.innerWidth < 480 &&
        !document.querySelector(".sidebar").classList.contains("toggled")
      ) {
        document.body.classList.add("sidebar-toggled");
        document.querySelector(".sidebar").classList.add("toggled");
        collapse(".sidebar .collapse", "toggle");
      }
    };
    const scrollToTop = (e) => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      e.preventDefault();
    };

    window.addEventListener("scroll", toggleScrollIcon);

    document
      .querySelectorAll("#sidebarToggle, #sidebarToggleTop")
      .forEach((element) => {
        element.addEventListener("click", toggleSidebarOnClick);
      });

    window.addEventListener("resize", toggleSidebarOnResize);

    document
      .querySelector("a.scroll-to-top")
      .addEventListener("click", scrollToTop);

    return function cleanup() {
      window.removeEventListener("resize", toggleSidebarOnResize);
      window.removeEventListener("scroll", toggleScrollIcon);
      try {
        document
          .querySelector("a.scroll-to-top")
          .removeEventListener("click", scrollToTop);
      } catch (err) {}

      document
        .querySelectorAll("#sidebarToggle, #sidebarToggleTop")
        .forEach((element) => {
          element.removeEventListener("click", toggleSidebarOnClick);
        });
    };
  }, []);

  return (
    <StyledUl
      className="navbar-nav sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      <a className="sidebar-brand d-flex align-items-center justify-content-center">
        <div className="sidebar-brand-icon d-none">
          <img src={WhiteLogoShort} style={{ width: "50px" }}></img>
        </div>
        <div className="sidebar-brand-text mx-3">
          <img style={{ width: "140px" }} src={Whitelogo}></img>
        </div>
      </a>

      <hr className="sidebar-divider my-0" />

      {/*  <li className="nav-item active">
        <Link className="nav-link" to="index.html" className="nav-link">
          <i className="fas fa-fw fa-tachometer-alt"></i>
          <span>Inicio</span>
        </Link>
      </li>

      <hr className="sidebar-divider" /> */}

      {/*       <div className="sidebar-heading">Rastreo de paquetes</div>
       */}
      <li className="nav-item">
        <a
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseTwo"
          aria-expanded="true"
          aria-controls="collapseTwo"
        >
          <i className="fas fa-truck-moving"></i>{" "}
          <span>Rastreo de paquetes</span>
        </a>
        <div
          id="collapseTwo"
          className="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">Sección de rastreo:</h6>
            <Link to="/admin/rastreo/estados" className="collapse-item">
              Estados
            </Link>
            <Link to="/admin/rastreo" className="collapse-item">
              Códigos de rastreo
            </Link>
          </div>
        </div>
      </li>
      <li className="nav-item">
        <a
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseThree"
          aria-expanded="true"
          aria-controls="collapseThree"
        >
          <i className="fas fa-info-circle"></i>
          <span>Información básica</span>
        </a>
        <div
          id="collapseThree"
          className="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">Inf básica:</h6>
            <Link to="/admin/clientes" className="collapse-item">
              Clientes
            </Link>
            <Link to="/admin/proveedores" className="collapse-item">
              Proveedores
            </Link>
          </div>
        </div>
      </li>

      {/*  <hr className="sidebar-divider" />

      <div className="sidebar-heading">Factibilidad</div>
      <li className="nav-item">
        <Link to="/admin/factibilidad/" className="nav-link">
          <i className="fas fa-clipboard-list"></i>
          <span>
            Ver solicitudes
            {pendingRequests ? (
              <span className="badge badge-danger badge-counter">
                {pendingRequests}
              </span>
            ) : (
              ""
            )}
          </span>
        </Link>
      </li>
      <hr className="sidebar-divider" />
      <div className="sidebar-heading">Mensajes de Contacto</div>
      <li className="nav-item">
        <Link to="/admin/mensajes/" className="nav-link">
          <i className="fas fa-envelope"></i>
          <span>
            Ver Mensajes Pendientes
            {pendingMessages !== 0 && (
              <span className="badge badge-danger badge-counter">
                {pendingMessages}
              </span>
            )}
          </span>
        </Link>

        <Link to="/admin/formularios-chat/" className="nav-link">
          <i className="fas fa-comments"></i>
          <span>Ver formularios de chat</span>
        </Link>
      </li>
      <hr className="sidebar-divider d-none d-md-block" />

      <div className="sidebar-heading">Usuarios</div>

      <li className="nav-item">
        <a
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseUser"
          aria-expanded="true"
          aria-controls="collapseUser"
        >
          <i className="fas fa-users"></i>
          <span>Creacion/edición</span>
        </a>
        <div
          id="collapseUser"
          className="collapse"
          aria-labelledby="headingUser"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">Opciones de usuario:</h6>
            <Link to="/admin/usuarios/" className="collapse-item">
              Ver/modificar usuarios
            </Link>
            <Link to="/admin/usuarios/crear" className="collapse-item">
              Crear usuario
            </Link>
          </div>
        </div>
      </li>
      <hr className="sidebar-divider d-none d-md-block" />
      <div className="sidebar-heading">Reportes de problemas</div>

      <li className="nav-item">
        <Link to="/admin/reportes" className="nav-link">
          <i className="fas fa-exclamation-triangle"></i>
          <span>Reportes</span>
        </Link>
      </li>
      <hr className="sidebar-divider d-none d-md-block" />
      <div className="sidebar-heading">Pagos reportados</div>

      <li className="nav-item">
        <Link to="/admin/pagos" className="nav-link">
          <i className="fas fa-money-check"></i>
          <span>Reportes de pago</span>
        </Link>
      </li>
      <hr className="sidebar-divider d-none d-md-block" />

      <div className="sidebar-heading">Configuración</div>

      <li className="nav-item">
        <Link to="/admin/reportes/categorias" className="nav-link">
          <i className="fas fa-fw fa-table"></i>
          <span>Estados y categorias de reporte</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link to="/admin/pagos/cuentas" className="nav-link">
          <i className="fas fa-piggy-bank"></i>
          <span>Cuentas de banco</span>
        </Link>
      </li> */}

      <div className="text-center d-none d-md-inline">
        <button className="rounded-circle border-0" id="sidebarToggle"></button>
      </div>
    </StyledUl>
  );
};

export default ClientPanelSidebar;
