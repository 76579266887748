import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import FormGroup from "../../Forms/FormGroup";
import * as Yup from "yup";
import createAlert from "../../../helpers/createAlert";
import SelectField from "../../Forms/SelectField";
import ProvidersService from "./Providers.service";
import { FormSubmitAction } from "../../Forms/FormSubmitAction";
import TextAreaField from "../../Forms/TextAreaField";
import Select from "react-select";

const CreateProviderForm = ({ data, ...props }) => {
  const [requestStatus, setRequestStatus] = useState({
    message: "",
    success: false,
    sent: false,
  });
  const [companyCategories, setCompanyCategories] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const loadIdentTypes = async () => {
      const result = await ProvidersService.getAllCompanyCategories();
      const countriesLoaded = await ProvidersService.getCountries();
      setCountries(countriesLoaded);
      setCompanyCategories(result.data);
    };
    loadIdentTypes();
  }, []);

  return (
    <Formik
      initialValues={{
        name: data ? data.name : "",
        contact: data ? data.contact : "",
        email: data ? data.email : "",
        phone: data ? data.phone : "",
        brands: data ? data.brands : "",
        webpage: data ? data.webpage : "",
        is_recommended: data ? data.is_recommended : "1",
        company_categories_id: data ? data.company_categories_id : "",
        country_id: data ? data.country_id : "",
        isactive: data ? data.isactive : "Y",
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        is_recommended: Yup.string().required("Este campo es necesario"),
        name: Yup.string().required("Este campo es necesario"),
        contact: Yup.string().required("Este campo es necesario"),
        phone: Yup.string().required("Este campo es necesario"),
        brands: Yup.string().required("Este campo es necesario"),
        webpage: Yup.string().required("Este campo es necesario"),
        company_categories_id: Yup.string().required("Este campo es necesario"),
        country_id: Yup.string().required("Este campo es necesario"),

        email: Yup.string()
          .email("Tiene que ser una dirección de email válida")
          .required("Este campo es necesario"),
        isactive: Yup.string().required("Este campo es necesario"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        if (data) {
          const result = await ProvidersService.update(
            values,
            data.providers_id
          );
          setRequestStatus(result);
          setSubmitting(false);
        } else {
          const result = await ProvidersService.create(values);
          setRequestStatus(result);
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="mx-2">
          <div className="row">
            <div className="col-12">{createAlert(requestStatus)}</div>
            <div className="col-12 col-md-4 col-lg-6">
              <FormGroup
                label="Nombre del proveedor"
                name="name"
                type="text"
              ></FormGroup>
            </div>
            <div className="col-12 col-md-4 col-lg-auto">
              <SelectField label="Proveedor recomendado" name="is_recommended">
                <option value="">Seleccione una opción</option>
                <option value="0">No</option>
                <option value="1">Sí</option>
              </SelectField>
            </div>
            <div className="col-12 col-md-4 col-lg-auto">
              <SelectField
                label="Tipo de proveedor"
                name="company_categories_id"
              >
                <option value="">Seleccione una opción</option>
                {companyCategories.map((category) => {
                  return (
                    <option value={category.ad_company_category_id}>
                      {category.name}
                    </option>
                  );
                })}
              </SelectField>
            </div>
            <div className="col-12 col-md-4 col-lg-4">
              <SelectField label="Ubicación" name="country_id">
                <option value="">Seleccione una opción</option>
                {countries.map((country) => {
                  return (
                    <option value={country.ad_country_id}>
                      {country.name}
                    </option>
                  );
                })}
              </SelectField>
            </div>

            <div className="col-12 col-md-4 col-lg-auto">
              <SelectField label="Proveedor activo" name="isactive">
                <option value="">Seleccione una opción</option>
                <option value="N">No</option>
                <option value="Y">Sí</option>
              </SelectField>
            </div>
            <div className="col-12 col-lg-7">
              <TextAreaField
                label="Marcas"
                name="brands"
                type="text"
              ></TextAreaField>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-1">
              <h4>Información de contacto</h4>
              <hr></hr>
            </div>
            <div className="col-12 col-lg-6">
              <FormGroup
                label="Nombre de contacto (*)"
                name="contact"
                type="text"
              ></FormGroup>
            </div>
            <div className="col-12 col-lg-6">
              <FormGroup
                label="Correo (*)"
                name="email"
                type="text"
              ></FormGroup>
            </div>
            <div className="col-12 col-lg-6">
              <FormGroup
                label="Teléfono (*)"
                name="phone"
                type="text"
              ></FormGroup>
            </div>
            <div className="col-12 col-lg-6">
              <FormGroup
                label="Página web"
                name="webpage"
                type="text"
              ></FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="form-group  mx-auto">
              <button
                type="submit"
                className=" btn-primary btn "
                disabled={isSubmitting}
              >
                {isSubmitting ? "Por favor espere" : "Enviar"}
              </button>
            </div>
          </div>
          <FormSubmitAction
            requestStatus={requestStatus}
            tableRef={props.tableRef}
          ></FormSubmitAction>
        </Form>
      )}
    </Formik>
  );
};

export default CreateProviderForm;
