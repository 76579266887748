import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { UnmountClosed } from "react-collapse";
import Spinner from "../Spinner/Spinner";
import createAlert from "../../helpers/createAlert";
import trackingStatusService from "../../services/TrackingModuleServices/trackingStatus.service";
import CreateTrackingForm from "./CreateTrackingForm";
import { TrackingTable } from "./TrackingTable";
import Dialogs from "../Modals/Dialogs";
import CreateClientForm from "../BasicInfoModule/Clients/CreateClientForm";
import CreateProviderForm from "../BasicInfoModule/Providers/CreateProviderForm";

const CreateTracking = () => {
  const [requestStatus, setRequestStatus] = useState({
    message: "",
    success: false,
    sent: false,
  });

  const tableRef = React.createRef();
  const [showForm, setFormShow] = useState(false);
  const [showData, setShowData] = useState();
  const [loading, setLoading] = useState(true);
  const [statuses, setStatuses] = useState();

  const [openFormModalClient, setOpenFormModalClient] = useState(false);
  const [openFormModalProvider, setOpenFormModalProvider] = useState(false);

  const handleButtons = (e, type) => {
    e.preventDefault();
    setShowData();
    setFormShow(type === "close" ? false : true);
  };

  useEffect(() => {
    const loadStatuses = async () => {
      const result = await trackingStatusService.getAllStatuses();
      setStatuses(result.data);
      setLoading(false);
    };
    loadStatuses();
  }, []);

  if (loading) {
    return <Spinner></Spinner>;
  }

  return (
    <>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Módulo de rastreo de paquetes</h1>
      </div>

      <UnmountClosed isOpened={showForm}>
        <div className="row">
          <div className="col-auto mx-auto pb-4">
            <div className="card shadow">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary float-left">
                  Crear / Editar número de rastreo
                </h6>
                <i
                  className="fas fa-times float-right"
                  onClick={(e) => handleButtons(e, "close")}
                  style={{ fontSize: "20px", color: "red" }}
                ></i>
              </div>
              <div className="card-body">
                <CreateTrackingForm
                  isClientModalOpen={openFormModalClient}
                  isProviderModalOpen={openFormModalProvider}
                  trackingInfo={showData}
                  statuses={statuses}
                  tableRef={tableRef}
                  setOpenFormModalClient={() => setOpenFormModalClient(true)}
                  setOpenFormModalProvider={() => setOpenFormModalProvider(true)}
                  closeCard={() => setFormShow(false)}
                ></CreateTrackingForm>
              </div>
            </div>
          </div>
        </div>
      </UnmountClosed>

      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            Rastreo de órdenes
          </h6>
        </div>
        <div className="card-body">
          <div className="row">
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleButtons(e, "open")}
            >
              Agregar nuevo número
            </Button>
            <div className="col-12 mx-auto">{createAlert(requestStatus)}</div>
            <div className="col-12 mx-auto">
              <TrackingTable
                ref={tableRef}
                setShowData={setShowData}
                setFormShow={setFormShow}
              />
            </div>
          </div>
        </div>
      </div>

      <Dialogs
        openModal={openFormModalClient}
        setOpenModal={setOpenFormModalClient}
        title="Crear nuevo cliente"
      >
        <CreateClientForm></CreateClientForm>
      </Dialogs>
      <Dialogs
        openModal={openFormModalProvider}
        setOpenModal={setOpenFormModalProvider}
        title="Crear nuevo proveedor"
      >
        <CreateProviderForm
        ></CreateProviderForm>
      </Dialogs>
    </>
  );
};

export default CreateTracking;
