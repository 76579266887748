import * as Yup from "yup";
import FormGroup from "../Forms/FormGroup";
import { Formik, Form } from "formik";
import styled from "styled-components";
import createAlert from "../../helpers/createAlert";
import { useState } from "react";
import Spinner from "../Spinner/Spinner";
import SelectField from "../Forms/SelectField";
import trackingService from "../../services/TrackingModuleServices/tracking.service";
import { consoleLogger } from "../../helpers/ConsoleLogger";
import dayjs from "dayjs";
import { FormSubmitAction } from "../Forms/FormSubmitAction";
import TextAreaField from "../Forms/TextAreaField";
import { useEffect } from "react";
import ClientsService from "../BasicInfoModule/Clients/Clients.service";
import ProvidersService from "../BasicInfoModule/Providers/Providers.service";
import MyMaterialButton from "../Button/Button";

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CreateTrackingForm = ({
  setOpenFormModalClient,
  isClientModalOpen,
  setOpenFormModalProvider,
  isProviderModalOpen,
  ...props
}) => {
  const [requestStatus, setRequestStatus] = useState({
    message: "",
    success: false,
    sent: false,
  });

  const [clients, setClients] = useState([]);
  const [providers, setProviders] = useState([]);

  const loadClients = async () => {
    const clients = await ClientsService.getAll();
    const providers = await ProvidersService.getAll();
    setClients(clients.data);
    setProviders(providers.data);
  };

  useEffect(() => {
    if (!isClientModalOpen && !isProviderModalOpen) {
      loadClients();
    }
  }, [isClientModalOpen, isProviderModalOpen]);

  return (
    <Formik
      initialValues={{
        status_id: props.trackingInfo ? props.trackingInfo.status_id : "",
        description: props.trackingInfo ? props.trackingInfo.description : "",
        clients_id: props.trackingInfo ? props.trackingInfo.clients_id : "",
        num_order: props.trackingInfo ? props.trackingInfo.num_order : "",
        providers_id: props.trackingInfo ? props.trackingInfo.providers_id : "",
        date_of_purchase: props.trackingInfo
          ? dayjs(props.trackingInfo.date_of_purchase).format("YYYY-MM-DD")
          : "",
        tracking: props.trackingInfo ? props.trackingInfo.tracking : "",
        tracking_number: props.trackingInfo
          ? props.trackingInfo.tracking_number
          : "",
        in_house: props.trackingInfo ? props.trackingInfo.in_house : "N",
        estimated_date: props.trackingInfo
          ? dayjs(props.trackingInfo.estimated_date).format("YYYY-MM-DD")
          : "",
        arrival_date:
          props.trackingInfo && props.trackingInfo.arrival_date
            ? dayjs(props.trackingInfo.arrival_date).format("YYYY-MM-DD")
            : "",
        isactive: props.trackingInfo ? props.trackingInfo.isactive : "Y",
      }}
      validationSchema={Yup.object({
        status_id: Yup.string().required("Este campo es necesario"),
        description: Yup.string(),
        clients_id: Yup.string().required("Este campo es necesario"),
        num_order: Yup.string().required("Este campo es necesario"),
        providers_id: Yup.string().required("Este campo es necesario"),
        date_of_purchase: Yup.date(
          "Este campo no tiene una fecha válida"
        ).required("Este campo es necesario"),
        tracking: Yup.string().required("Este campo es necesario"),
        tracking_number: Yup.string(),
        in_house: Yup.string().required("Este campo es necesario"),
        estimated_date: Yup.date(
          "Este campo no tiene una fecha válida"
        ).required("Este campo es necesario"),
        arrival_date: Yup.date(
          "Este campo no tiene una fecha válida"
        ).nullable(),
        isactive: Yup.string().required("Este campo es necesario"),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        consoleLogger(values);
        if (props.trackingInfo) {
          const result = await trackingService.update(
            values,
            props.trackingInfo.gb_tracing_id
          );
          setRequestStatus(result);
          setSubmitting(false);
        } else {
          const result = await trackingService.create(values);
          setRequestStatus(result);
          setSubmitting(false);
        }
      }}
      enableReinitialize={true}
    >
      {({ isSubmitting }) => (
        <StyledForm>
          <div className="row ">
            <MyMaterialButton icon="Add" onClick={setOpenFormModalClient}>
              Agregar cliente
            </MyMaterialButton>
            <MyMaterialButton icon="Add" onClick={setOpenFormModalProvider}>
              Agregar proveedor
            </MyMaterialButton>
            <div className="col-12">{createAlert(requestStatus)}</div>

            <div className="col-12 ">
              <div className="row">
                <div className="col-12 col-lg-3">
                  <FormGroup
                    label="Tracking para el cliente"
                    name="tracking_number"
                    type="text"
                    disabled={true}
                  ></FormGroup>
                </div>
                <div className="col-12 col-sm-12 col-lg-4">
                  <FormGroup
                    label="Número de orden"
                    name="num_order"
                    type="text"
                  ></FormGroup>
                </div>
                <div className="col-12 col-sm-12 col-lg-4">
                  <SelectField label="Estado" name="status_id">
                    <option value=""></option>
                    {props.statuses.map((status) => {
                      return (
                        <option value={status.gb_status_id}>
                          {status.status}
                        </option>
                      );
                    })}
                  </SelectField>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <SelectField label="Cliente" name="clients_id" type="text">
                <option value=""></option>

                {clients.map((client) => {
                  return (
                    <option value={client.clients_id}>{client.name}</option>
                  );
                })}
              </SelectField>
            </div>
            <div className="col-12 col-lg-4">
              <SelectField label="Proveedor" name="providers_id" type="text">
                <option value=""></option>
                {providers.map((provider) => {
                  return (
                    <option value={provider.providers_id}>
                      {provider.name}
                    </option>
                  );
                })}
              </SelectField>
            </div>
            <div className="col-12 col-lg-3">
              <FormGroup
                label="Fecha de compra"
                name="date_of_purchase"
                type="date"
              ></FormGroup>
            </div>
            <div className="col-12 col-lg-4">
              <FormGroup
                label="Tracking Interno"
                name="tracking"
                type="text"
              ></FormGroup>
            </div>
            <div className="col-12">
              <div className="row d-flex">
                <div className="col-12 col-lg-4 ">
                  <FormGroup
                    label="Fecha estimada"
                    name="estimated_date"
                    type="date"
                  ></FormGroup>
                </div>
                <div className="col-12 col-lg-4 ">
                  <FormGroup
                    label="Fecha de llegada"
                    name="arrival_date"
                    type="date"
                  ></FormGroup>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4">
              <TextAreaField
                label="Descripción"
                name="description"
                type="text"
                rows="5"
              ></TextAreaField>
            </div>
            <div className="col-12 col-lg-2">
              <SelectField label="Registro activo" name="isactive">
                <option value="">Seleccione una opción</option>
                <option value="Y">Sí</option>
                <option value="N">No</option>
              </SelectField>
            </div>
            <div className="col-12 col-lg-2">
              <SelectField label="Ya en almacen" name="in_house">
                <option value="">Seleccione una opción</option>
                <option value="Y">Sí</option>
                <option value="N">No</option>
              </SelectField>
            </div>
          </div>
          <div className="row text-center my-3">
            <div className="form-group">
              {isSubmitting ? <Spinner></Spinner> : ""}
              <button
                type="submit"
                className=" btn-primary btn "
                disabled={isSubmitting}
              >
                {isSubmitting
                  ? "Por favor espere"
                  : props.trackingInfo
                  ? "Actualizar Número de rastreo"
                  : "Crear Número de rastreo"}
              </button>
            </div>
          </div>
          <FormSubmitAction
            closeCard={props.closeCard}
            requestStatus={requestStatus}
            tableRef={props.tableRef}
          ></FormSubmitAction>
        </StyledForm>
      )}
    </Formik>
  );
};

export default CreateTrackingForm;
