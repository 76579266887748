import React from "react";
import MaterialTable, { MTableCell } from "material-table";
import { Paper } from "@material-ui/core";
import ClientsService from "./Clients.service";
import { localization, optionsTable, tableIcons } from "../../../helpers/TableHelpers";

export const ClientsTable = React.forwardRef(
  ({ setShowData, setFormShow }, ref) => {
    return (
      <MaterialTable
      icons={tableIcons}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
          Cell: (props) => <MTableCell {...props} align="center" />,
        }}
        title="Clientes registrados"
        tableRef={ref}
        localization={localization("Nro de orden o cliente")}
        columns={[
          {
            title: "Cedula/rif",
            render: ({ identification, identification_type }) =>
              identification_type.type + "-" + identification,
          },
          {
            title: "Cliente",
            field: "name",
          },
          {
            title: "Contacto",
            field: "contact_name",
          },
          {
            title: "Número",
            field: "contact_phone",
          },
          {
            title: "Correo",
            field: "contact_email",
          },
        ]}
        options={optionsTable}
        data={(query) =>
          new Promise(async (resolve, reject) => {
            const result = await ClientsService.getAllPaginated({
              page: query.page + 1,
              limit: query.pageSize,
              search: query.search,
            });

            resolve({
              page: parseInt(result.meta.current_page - 1),
              data: result.data,
              totalCount: parseInt(result.meta.total),
            });
          })
        }
        actions={[
          {
            icon: "refresh",
            tooltip: "Refrescar datos",
            isFreeAction: true,
            onClick: () => ref.current && ref.current.onQueryChange(),
          },
          {
            icon: "edit",
            tooltip: "Editar Información",
            onClick: (event, rowData) => {
              setShowData(rowData);
              setFormShow(true);
            },
          },
        ]}
      />
    );
  }
);
