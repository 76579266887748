import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import FormGroup from "../../Forms/FormGroup";
import * as Yup from "yup";
import createAlert from "../../../helpers/createAlert";
import SelectField from "../../Forms/SelectField";
import DependentSelect from "../../Forms/DependentSelect";
import IdentificationTypesService from "./IdentificationTypes.service";
import ClientsService from "./Clients.service";
import { FormSubmitAction } from "../../Forms/FormSubmitAction";

const CreateClientForm = ({ data, ...props }) => {
  const [requestStatus, setRequestStatus] = useState({
    message: "",
    success: false,
    sent: false,
  });

  const [isEnterprise, setIsEnterprise] = useState("");

  const [selectTypes, setSelectTypes] = useState([]);

  useEffect(() => {
    const loadIdentTypes = async () => {
      const result = await IdentificationTypesService.getAllIdentTypes();
      setSelectTypes(result.data);
    };
    loadIdentTypes();
  }, []);

  return (
    <Formik
      initialValues={{
        identification: data
          ? data.identification
            ? data.identification
            : ""
          : "",
        name: data ? (data.name ? data.name : "") : "",
        contact_email: data
          ? data.contact_email
            ? data.contact_email
            : ""
          : "",
        contact_phone: data
          ? data.contact_phone
            ? data.contact_phone
            : ""
          : "",
        contact_address: data
          ? data.contact_address
            ? data.contact_address
            : ""
          : "",
        is_for_company: data ? data.identification_type.is_for_company : "",
        contact_name: data ? data.contact_name : "",
        identification_types_id: data
          ? data.identification_types_id
            ? data.identification_types_id
            : ""
          : "",
        isactive: data ? data.isactive : "",
      }}

      enableReinitialize={true}

      validationSchema={Yup.object({
        is_for_company: Yup.string().required(
          "Necesita especificar el tipo de cliente"
        ),
        identification: Yup.string().required("Necesario"),
        name: Yup.string().required("Este campo es necesario"),
        contact_email: Yup.string()
          .email("Tiene que ser una dirección de email válida")
          .required("Este campo es necesario"),
        contact_phone: Yup.string().required("Este campo es necesario"),
        identification_types_id: Yup.string().required(
          "Este campo es necesario"
        ),
        contact_address: Yup.string().required("La dirección es necesaria"),
        contact_name: Yup.string().required("Este campo es necesario"),
        isactive: Yup.string().required("Este campo es necesario"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        if (data) {
          const result = await ClientsService.update(values, data.clients_id);
          setRequestStatus(result);
          setSubmitting(false);
        } else {
          const result = await ClientsService.create(values);
          setRequestStatus(result);
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className="mx-2">
          <div className="row">
            <div className="col-12">{createAlert(requestStatus)}</div>
            <div className="col-12 col-md-4 col-lg-auto">
              <SelectField
                label="Tipo de cliente"
                name="is_for_company"
                onChange={(e) => {
                  const newEnterpriseSelect = e.target.value;
                  const shouldResetDependentSelect =
                    newEnterpriseSelect !== isEnterprise;
                  setIsEnterprise(newEnterpriseSelect);
                  setFieldValue(e.target.name, e.target.value);
                  if (shouldResetDependentSelect) {
                    setFieldValue("identification_types_id", "");
                  }
                }}
              >
                <option value="">Seleccione el tipo de cliente</option>
                <option value="N">Natural</option>
                <option value="Y">Juridico</option>
              </SelectField>
            </div>
            <div className="col-12 col-md-4 col-lg-6">
              <FormGroup
                label="Nombre o compañia"
                name="name"
                type="text"
              ></FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-md-2 col-sm-2 col-lg-auto">
              <SelectField label="." name="identification_types_id">
                <option value=""></option>
                {selectTypes.map((type) => {
                  if (
                    values.is_for_company === "Y" &&
                    type.is_for_company === "Y"
                  ) {
                    return (
                      <option value={type.identification_types_id}>
                        {type.type}
                      </option>
                    );
                  }
                  if (
                    values.is_for_company === "N" &&
                    type.is_for_company === "N"
                  ) {
                    return (
                      <option value={type.identification_types_id}>
                        {type.type}
                      </option>
                    );
                  }
                })}
              </SelectField>
            </div>
            <div className="col-8 col-md-4 col-lg-4">
              <FormGroup
                label="Cedula/Rif (*)"
                name="identification"
                type="text"
              ></FormGroup>
            </div>
            <div className="col-12 col-lg-auto">
              <SelectField label="Cliente activo" name="isactive" type="text">
                <option value=""></option>
                <option value="Y">Sí</option>
                <option value="N">No</option>
              </SelectField>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-1">
              <h4>Información de contacto</h4>
              <hr></hr>
            </div>
            <div className="col-12 col-lg-6">
              <FormGroup
                label="Nombre de contacto (*)"
                name="contact_name"
                type="text"
              ></FormGroup>
            </div>
            <div className="col-12 col-lg-6">
              <FormGroup
                label="Dirección (*)"
                name="contact_address"
                type="text"
              ></FormGroup>
            </div>
            <div className="col-12 col-lg-6">
              <FormGroup
                label="Correo (*)"
                name="contact_email"
                type="text"
              ></FormGroup>
            </div>
            <div className="col-12 col-lg-6">
              <FormGroup
                label="Teléfono (*)"
                name="contact_phone"
                type="text"
              ></FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="form-group  mx-auto">
              <button
                type="submit"
                className=" btn-primary btn "
                disabled={isSubmitting}
              >
                {isSubmitting ? "Por favor espere" : "Enviar"}
              </button>
            </div>
          </div>
          <FormSubmitAction
            requestStatus={requestStatus}
            tableRef={props.tableRef}
          ></FormSubmitAction>
        </Form>
      )}
    </Formik>
  );
};

export default CreateClientForm;
