import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./components/Styling/GlobalStyles";
import AdminPanelLayout from "./pages/Layouts/AdminPanelLayout/AdminPanelLayout";
import LoginPage from "./pages/LoginPage";
import CreateTrackingStatus from "./components/TrackingModule/CreateTrackingStatus";
import CreateTracking from "./components/TrackingModule/CreateTracking";
import PrivateSupportRoute from "./helpers/PrivateSupportRoute";
import "@fortawesome/fontawesome-free/css/all.css";
import { Provider } from "react-redux";
import store from "./store";
import { setAuthToken } from "./helpers/authHelpers";
import { useEffect } from "react";
import { loadUser } from "./actions/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateClient from "./components/BasicInfoModule/Clients/CreateClient";
import ProvidersHome from "./components/BasicInfoModule/Providers/ProvidersHome";

const theme = {
  colors: {
    primaryBackground: "#276ef1",
    secondaryBackgrund: "white",
    primary: "#0070f3",
    blue: "#276ef1",
    lightBlue: "#5799fb",
    darkBlue: "#111D7D",
    darkerBlue: "#000036",
  },
};

if (localStorage.token) {
  setAuthToken(JSON.parse(localStorage.token));
}

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Switch>
            <Route exact path="/" component={LoginPage}></Route>
            <Route exact path="/login" component={LoginPage}></Route>
            <PrivateSupportRoute
              layout={AdminPanelLayout}
              path="/admin/rastreo"
              exact
              component={CreateTracking}
            />
            <PrivateSupportRoute
              layout={AdminPanelLayout}
              path="/admin/rastreo/estados"
              exact
              component={CreateTrackingStatus}
            />
            <PrivateSupportRoute
              layout={AdminPanelLayout}
              path="/admin/clientes/"
              exact
              component={CreateClient}
            />

            <PrivateSupportRoute
              layout={AdminPanelLayout}
              path="/admin/proveedores/:page?"
              exact
              component={ProvidersHome}
            />
          </Switch>
        </ThemeProvider>
      </Router>
      <ToastContainer />
    </Provider>
  );
}

export default App;
