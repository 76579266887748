import React, { useEffect } from "react";
import { useState } from "react";
import { UnmountClosed } from "react-collapse";
import createAlert from "../../../helpers/createAlert";
import CreateProviderForm from "./CreateProviderForm";
import ProvidersTable from "./ProvidersTable";
import ProvidersService from "./Providers.service";
import MyMaterialButton from "../../Button/Button";

const CreateProvider = () => {
  const [requestStatus, setRequestStatus] = useState({
    message: "",
    success: false,
    sent: false,
  });

  const tableRef = React.createRef();
  const [showForm, setFormShow] = useState(false);
  const [showData, setShowData] = useState();
  const [companyCategories, setCompanyCategories] = useState([]);

  const handleButtons = (e, type) => {
    e.preventDefault();
    setShowData();
    setFormShow(type === "close" ? false : true);
  };

  useEffect(() => {
    const loadStatuses = async () => {
      const result = await ProvidersService.getAllCompanyCategories();
      setCompanyCategories(result.data);
    };
    loadStatuses();
  }, []);

  return (
    <>
      <UnmountClosed isOpened={showForm}>
        <div className="row">
          <div className="col-auto mx-auto pb-4">
            <div className="card shadow">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary float-left">
                  Formulario de proveedores
                </h6>
                <i
                  className="fas fa-times float-right"
                  onClick={(e) => handleButtons(e, "close")}
                  style={{ fontSize: "20px", color: "red" }}
                ></i>
              </div>
              <div className="card-body">
                <CreateProviderForm
                  companyCategories={companyCategories}
                  data={showData}
                  tableRef={tableRef}
                ></CreateProviderForm>
              </div>
            </div>
          </div>
        </div>
      </UnmountClosed>

      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Proveedores</h6>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-12 mx-auto">
              <MyMaterialButton
                icon="Add"
                label={"Agregar nuevo proveedor"}
                onClick={(e) => handleButtons(e, "open")}
              />
            </div>
            <div className="col-12 mx-auto">{createAlert(requestStatus)}</div>
            <div className="col-12 mx-auto">
              <ProvidersTable
                ref={tableRef}
                setShowData={setShowData}
                setFormShow={setFormShow}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProvider;
