import React from "react";
import CreateProvider from "./CreateProvider";
import useTabsHook from "../../../helpers/TabsHelpder";
import TabsRenderer from "../../Navbars/TabsRenderer";
import CreateProviderType from "./CreateProviderType";

const ProvidersHome = (props) => {
  const [selectedTab, handleChange] = useTabsHook(
    {
      0: "ver",
      1: "tipo",
    },
    "/admin/proveedores/"
  );

  return (
    <>
      <TabsRenderer
        selectedTab={selectedTab}
        handleChange={handleChange}
        tabs={["Ver proveedores", "Tipos de proveedores"]}
      ></TabsRenderer>
      {selectedTab === 0 && <CreateProvider />}
      {selectedTab === 1 && <CreateProviderType />}
    </>
  );
};

export default ProvidersHome;
