import { Button } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { UnmountClosed } from "react-collapse";
import TrackingStatusDnD from "./TrackingStatus/TrackingStatusDnD";
import CreateTrackingStatusForm from './TrackingStatus/CreateTrackingStatusForm';
import { TrackingStatusesTable } from "./TrackingStatus/TrackingStatusesTable";

const CreateTrackingStatus = () => {
  const tableRef = React.createRef();
  const [showForm, setFormShow] = useState(false);
  const [showOrderCard, setShowOrderCard] = useState(false);
  const [showData, setShowData] = useState();
  const [statuses, setStatuses] = useState();

  const handleButtons = (e, type) => {
    e.preventDefault();
    setShowData();
    setShowOrderCard(false);
    setFormShow(type === "close" ? false : true);
  };

  const handleOrderButton = (e, type) => {
    e.preventDefault();
    setShowData();
    setFormShow(false);
    setShowOrderCard(type === "close" ? false : true);
  };

  return (
    <>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Estados de rastreo de paquetes </h1>
      </div>

      <UnmountClosed isOpened={showOrderCard}>
        <div className="row">
          <div className="col-auto mx-auto pb-4">
            <div className="card shadow">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary float-left">
                  Ordenar estados
                </h6>
                <i
                  className="fas fa-times float-right"
                  onClick={(e) => handleButtons(e, "close")}
                  style={{ fontSize: "20px", color: "red" }}
                ></i>
              </div>
              <div className="card-body d-flex flex-column justify-content-center align-items-center">
                <TrackingStatusDnD statuses={statuses}></TrackingStatusDnD>
              </div>
            </div>
          </div>
        </div>
      </UnmountClosed>

      <UnmountClosed isOpened={showForm}>
        <div className="row">
          <div className="col-auto mx-auto pb-4">
            <div className="card shadow">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary float-left">
                  Crear / Editar estado
                </h6>
                <i
                  className="fas fa-times float-right"
                  onClick={(e) => handleButtons(e, "close")}
                  style={{ fontSize: "20px", color: "red" }}
                ></i>
              </div>
              <div className="card-body">
                <CreateTrackingStatusForm
                  tableRef={tableRef}
                  statusInfo={showData}
                  closeCard={()=>setFormShow(false)}
                ></CreateTrackingStatusForm>
              </div>
            </div>
          </div>
        </div>
      </UnmountClosed>

      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Estados</h6>
        </div>
        <div className="card-body">
          <div className="row">
            <Button
              variant="contained"
              color="primary"
              className="m-1"
              onClick={(e) => handleButtons(e, "open")}
            >
              Agregar nuevo estado
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="m-1"
              onClick={(e) => handleOrderButton(e, "open")}
            >
              Ordenar estados
            </Button>
            <div className="col-12 mx-auto">
              <TrackingStatusesTable
                ref={tableRef}
                setShowData={setShowData}
                setFormShow={setFormShow}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTrackingStatus;
