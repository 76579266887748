import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  USER_LOADED,
  AUTH_ERROR,
  AUTH_LOADED,
  AUTH_LOADING,
  LOGOUT,
} from "../actions/types";
import { setAuthToken } from "../helpers/authHelpers";

const initialState = {
  token: JSON.parse(localStorage.getItem("token")),
  isAuthenticated: null,
  loading: true,
  user: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_SUCCESS:
      localStorage.setItem("token", JSON.stringify(payload));
      setAuthToken(payload);
      return {
        ...state,
        token: {
          ...payload,
        },
        isAuthenticated: true,
        loading: false,
      };

    case LOGOUT:
      localStorage.removeItem("token");
      setAuthToken("");
      return {
        ...state,
        token: "",
        user: {},
        isAuthenticated: false,
        loading: false,
      };

    case AUTH_ERROR:
    case LOGIN_FAIL:
      localStorage.removeItem("token");
      return {
        ...state,
        ...payload,
        token: null,
        isAuthenticated: false,
        loading: false,
      };

    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };
    case AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case AUTH_LOADED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
