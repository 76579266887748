import Axios from "axios";

export const axios = Axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://backend.industriasimpro.com/api/"
      : "http://localhost:8000/api/",
  headers: {
    "Content-Type": "application/json",
  },
});
