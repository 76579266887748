import React, { useState } from "react";
import { Formik, Form } from "formik";
import FormGroup from "../../Forms/FormGroup";
import * as Yup from "yup";
import createAlert from "../../../helpers/createAlert";
import SelectField from "../../Forms/SelectField";
import ProvidersService from "./Providers.service";
import { FormSubmitAction } from "../../Forms/FormSubmitAction";

const CreateProviderTypeForm = ({ data, companyCategories, ...props }) => {
  const [requestStatus, setRequestStatus] = useState({
    message: "",
    success: false,
    sent: false,
  });

  return (
    <Formik
      initialValues={{
        name: data ? data.name : "",
        description: data ? data.description : "",
        isactive: data ? data.isactive : "Y",
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        name: Yup.string().required("Este campo es necesario"),
        description: Yup.string().required("Este campo es necesario"),
        isactive: Yup.string().required("Este campo es necesario"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        if (data) {
          const result = await ProvidersService.updateProviderType(
            values,
            data.ad_company_category_id
          );
          setRequestStatus(result);
          setSubmitting(false);
        } else {
          const result = await ProvidersService.createProviderType(values);
          setRequestStatus(result);
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form className="mx-2">
          <div className="row">
            <div className="col-12">{createAlert(requestStatus)}</div>
            <div className="col-12 col-md-4 col-lg-6">
              <FormGroup
                label="Nombre del tipo de proveedor"
                name="name"
                type="text"
              ></FormGroup>
            </div>
            <div className="col-12 col-md-4 col-lg-6">
              <FormGroup
                label="Descripción"
                name="description"
                type="text"
              ></FormGroup>
            </div>
            <div className="col-12 col-md-4 col-lg-auto">
              <SelectField label="Registro activo" name="isactive">
                <option value="">Seleccione una opción</option>
                <option value="N">No</option>
                <option value="Y">Sí</option>
              </SelectField>
            </div>
          </div>
          <div className="row">
            <div className="form-group  mx-auto">
              <button
                type="submit"
                className=" btn-primary btn "
                disabled={isSubmitting}
              >
                {isSubmitting ? "Por favor espere" : "Enviar"}
              </button>
            </div>
          </div>
          <FormSubmitAction
            requestStatus={requestStatus}
            tableRef={props.tableRef}
          ></FormSubmitAction>
        </Form>
      )}
    </Formik>
  );
};

export default CreateProviderTypeForm;
