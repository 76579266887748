import { axios } from "../../../helpers/AxiosInstance";
import { consoleLogger } from "../../../helpers/ConsoleLogger";

const API_ENDPOINT = "identificationTypes";

const create = async (status) => {
  const result = await axios
    .post(API_ENDPOINT, status)
    .then((data) => {
      return {
        success: true,
        message: "Identificación creada con éxito",
        sent: true,
      };
    })
    .catch((err) => {
      consoleLogger(err);
      return {
        success: false,
        message: "Parece que hubo un problema registrando el estado",
        sent: true,
      };
    });
  return result;
};

const getAllIdentTypes = async () => {
  try {
    const result = await axios.get(API_ENDPOINT + "/all");
    consoleLogger(result);
    return result.data;
  } catch (error) {
    consoleLogger(error);
  }
};

const getAllIdentTypesPaginated = async (paginationData) => {
  try {
    const result = await axios.get(API_ENDPOINT, {
      params: {
        page: paginationData.page,
        limit: parseInt(paginationData.limit),
        search: paginationData.search,
      },
    });
    consoleLogger(result);

    return result.data;
  } catch (error) {
    consoleLogger(error);
  }
};

const update = async (values, identId) => {
  const result = await axios
    .put(API_ENDPOINT + "/" + identId, values)
    .then((result) => {
      consoleLogger(result.data.data);
      return {
        success: true,
        message: "Identificación actualizada correctamente",
        sent: true,
      };
    })
    .catch((error) => {
      consoleLogger(error);

      return {
        success: false,
        message: "Hubo un problema actualizando la identificación",
        sent: true,
      };
    });
  return result;
};

export default {
  getAllIdentTypes,
  create,
  update,
  getAllIdentTypesPaginated,
};
