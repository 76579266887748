import { useFormikContext } from "formik";
import { useEffect } from "react";
import { toast } from "react-toastify";

export const FormSubmitAction = ({ closeCard, requestStatus, tableRef }) => {
  const { resetForm } = useFormikContext();
  useEffect(() => {
    if (requestStatus.sent) {
      if (requestStatus.success) {
        resetForm();
        if (tableRef) {
          tableRef.current && tableRef.current.onQueryChange();
        }
        toast.success(requestStatus.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(requestStatus.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [requestStatus]);

  return null;
};
