import * as Yup from "yup";
import FormGroup from "../FormGroup";
import { Formik, Form } from "formik";
import styled from "styled-components";
import createAlert from "../../../helpers/createAlert";
import { useEffect, useState } from "react";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../../actions/auth";
import PropTypes from "prop-types";
import Spinner from "../../Spinner/Spinner";
import { toast } from "react-toastify";

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginForm = ({ login, isAuthenticated }) => {
  const history = useHistory();

  const [requestStatus, setRequestStatus] = useState({
    message: "",
    success: false,
    sent: false,
    redirect: "",
  });

  const loginUser = async (userData) => {
    await login(userData);
    if (isAuthenticated) {
      setRequestStatus({
        message: "Ha logeado correctamente",
        success: true,
        sent: true,
        redirect: "/admin/rastreo",
      });
    } else {
      setRequestStatus({
        message: "Parece que su usuario o contraseña no son correctos",
        success: false,
        sent: true,
        redirect: "",
      });
    }
  };

  useEffect(() => {
    if (requestStatus.sent === true && requestStatus.success) {
      toast.success('Sesión iniciada correctamente, redireccionando...', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });      
/*       history.push(requestStatus.redirect);
      history.go(); */
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return <Redirect to="/admin/rastreo"></Redirect>;
  }

  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      validationSchema={Yup.object({
        username: Yup.string().required("Este campo es necesario"),
        password: Yup.string().required("Este campo es necesario"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        await loginUser(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <StyledForm>
          <div className="row ">
            <div className="col-12">{createAlert(requestStatus)}</div>
            <div className="col-10 mx-auto">
              <FormGroup
                label="Usuario"
                name="username"
                type="text"
              ></FormGroup>
            </div>
            <div className="col-10 mx-auto">
              <FormGroup
                label="Contraseña"
                name="password"
                type="password"
              ></FormGroup>
            </div>
          </div>
          <div className="row text-center my-3">
            <div className="form-group">
              <button
                type="submit"
                className=" btn-primary btn "
                disabled={isSubmitting}
              >
                {isSubmitting ? "Por favor espere" : "Ingresar"}
              </button>
            </div>
          </div>
        </StyledForm>
      )}
    </Formik>
  );
};

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(LoginForm);
