import { Form, Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { consoleLogger } from "../../../helpers/ConsoleLogger";
import createAlert from "../../../helpers/createAlert";
import trackingStatusService from "../../../services/TrackingModuleServices/trackingStatus.service";
import Spinner from "../../Spinner/Spinner";
import FormGroup from "../../Forms/FormGroup";
import SelectField from "../../Forms/SelectField";
import { FormSubmitAction } from "../../Forms/FormSubmitAction";

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CreateTrackingStatusForm = (props) => {
  const [requestStatus, setRequestStatus] = useState({
    message: "",
    success: false,
    sent: false,
  });

  
  return (
    <Formik
      initialValues={{
        ad_org_id: 1,
        status: props.statusInfo ? props.statusInfo.status : "",
        description: props.statusInfo ? props.statusInfo.description : "",
        isactive: props.statusInfo ? props.statusInfo.isactive : "",
      }}
      validationSchema={Yup.object({
        status: Yup.string().required("Este campo es necesario"),
        description: Yup.string().required("Este campo es necesario"),
        isactive: Yup.string().required("Este campo es necesario"),
      })}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        let result;
        consoleLogger(values);
        if (props.statusInfo) {
          result = await trackingStatusService.update(
            values,
            props.statusInfo.gb_status_id
          );
        } else {
          result = await trackingStatusService.create(values);
        }
        setRequestStatus(result);
        setSubmitting(false);
      
      }}
      enableReinitialize={true}
    >
      {({ isSubmitting }) => (
        <StyledForm>
          <div className="row">
            <div className="col-12">{createAlert(requestStatus)}</div>

            <div className="col-12 col-md-3 mx-auto">
              <FormGroup label="Estado" name="status" type="text"></FormGroup>
            </div>
            <div className="col-12 col-md-5 mx-auto">
              <FormGroup
                label="Descripción"
                name="description"
                type="text"
              ></FormGroup>
            </div>
            <div className="col-12 col-md-3 mx-auto">
              <SelectField label="Registro activo" name="isactive">
                <option value="">Seleccione una opción</option>
                <option value="Y">Sí</option>
                <option value="N">No</option>
              </SelectField>
            </div>
          </div>
          <div className="row text-center my-3">
            <div className="form-group">
              {isSubmitting ? <Spinner></Spinner> : ""}
              <button
                type="submit"
                className=" btn-primary btn "
                disabled={isSubmitting}
              >
                {isSubmitting
                  ? "Por favor espere"
                  : props.statusInfo
                  ? "Actualizar Estado"
                  : "Crear Estado"}
              </button>
            </div>
          </div>
          <FormSubmitAction closeCard={props.closeCard} requestStatus={requestStatus} tableRef={props.tableRef}></FormSubmitAction>
        </StyledForm>
      )}
    </Formik>
  );
};

export default CreateTrackingStatusForm;
