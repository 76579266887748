import { Button } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { UnmountClosed } from "react-collapse";
import CreateProviderTypeForm from "./CreateProviderTypeForm";
import createAlert from "../../../helpers/createAlert";
import ProviderTypesTable from "./ProviderTypesTable";
import AddIcon from "@material-ui/icons/Add";
import MyMaterialButton from "../../Button/Button";

const CreateProviderType = () => {
  const [requestStatus, setRequestStatus] = useState({
    message: "",
    success: false,
    sent: false,
  });

  const tableRef = React.createRef();
  const [showForm, setFormShow] = useState(false);
  const [showData, setShowData] = useState();

  const handleButtons = (e, type) => {
    e.preventDefault();
    setShowData();
    setFormShow(type === "close" ? false : true);
  };

  return (
    <>
      <UnmountClosed isOpened={showForm}>
        <div className="row">
          <div className="col-auto mx-auto pb-4">
            <div className="card shadow">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary float-left">
                  Formulario de Tipos de proveedores
                </h6>
                <i
                  className="fas fa-times float-right"
                  onClick={(e) => handleButtons(e, "close")}
                  style={{ fontSize: "20px", color: "red" }}
                ></i>
              </div>
              <div className="card-body">
                <CreateProviderTypeForm
                  data={showData}
                  tableRef={tableRef}
                ></CreateProviderTypeForm>
              </div>
            </div>
          </div>
        </div>
      </UnmountClosed>

      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            Tipo de proveedor
          </h6>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-12 mx-auto">
              <MyMaterialButton
                icon="Add"
                onClick={(e) => handleButtons(e, "open")}
              >
                Agregar nuevo Tipo
              </MyMaterialButton>
            </div>
          </div>
          <div className="col-12 mx-auto">{createAlert(requestStatus)}</div>
          <div className="col-12 mx-auto">
            <ProviderTypesTable
              ref={tableRef}
              setShowData={setShowData}
              setFormShow={setFormShow}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProviderType;
