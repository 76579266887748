import { useHistory, useParams } from "react-router";
import React from "react";

const useTabsHook = (tabNameToIndex, url) => {
  let history = useHistory();
  let { page } = useParams();

  const indexToTabName = swap(tabNameToIndex);

  const [selectedTab, setSelectedTab] = React.useState(
    indexToTabName[page ? page : tabNameToIndex[0]]
  );

  const handleChange = (event, newValue) => {
    history.push(url + tabNameToIndex[newValue]);
    setSelectedTab(newValue);
  };

  return [selectedTab, handleChange];
};

function swap(json) {
  var ret = {};
  for (var key in json) {
    ret[json[key]] = parseInt(key);
  }
  return ret;
}

export default useTabsHook;
