import { axios } from "../../../helpers/AxiosInstance";
import { consoleLogger } from "../../../helpers/ConsoleLogger";

const API_ENDPOINT = "providers";

const create = async (status) => {
  const result = await axios
    .post(API_ENDPOINT, status)
    .then((data) => {
      consoleLogger(data);
      return {
        success: true,
        message: "El proveedor ha sido creado correctamente",
        sent: true,
      };
    })

    .catch((error) => {
      consoleLogger(error.response);
      return {
        success: false,
        message: "Parece que hubo un problema en el registro",
        sent: true,
      };
    });
  return result;
};

const getCountries = async () => {
  const result = await axios
    .get("countries/all")
    .then((data) => {
      consoleLogger(data);
      return data.data.data;
    })
    .catch((error) => {
      consoleLogger(error.response);
      return error.response;
    });
  return result;
};

const createProviderType = async (status) => {
  const result = await axios
    .post("companyCategories", status)
    .then((data) => {
      consoleLogger(data);

      return {
        success: true,
        message: "El tipo de proveedor  ha sido creado con éxito",
        sent: true,
      };
    })
    .catch((error) => {
      consoleLogger(error);
      return {
        success: false,
        message: "Parece que hubo un problema en el registro",
        sent: true,
      };
    });
  return result;
};

const getAllPaginated = async (paginationData) => {
  try {
    const result = await axios.get(API_ENDPOINT, {
      params: {
        page: paginationData.page,
        limit: parseInt(paginationData.limit),
        search: paginationData.search,
      },
    });
    consoleLogger(result);

    return result.data;
  } catch (error) {
    consoleLogger(error);
  }
};
const getAll = async () => {
  try {
    const result = await axios.get(API_ENDPOINT + "/all");
    consoleLogger(result);

    return result.data;
  } catch (error) {
    consoleLogger(error);
  }
};

const getAllCompanyCategoriesPaginated = async (paginationData) => {
  try {
    const result = await axios.get("companyCategories", {
      params: {
        page: paginationData.page,
        limit: parseInt(paginationData.limit),
        search: paginationData.search,
      },
    });
    consoleLogger(result);

    return result.data;
  } catch (error) {
    consoleLogger(error);
  }
};

const getAllCompanyCategories = async (paginationData) => {
  try {
    const result = await axios.get("companyCategories/all");
    consoleLogger(result);

    return result.data;
  } catch (error) {
    consoleLogger(error);
  }
};

const update = async (values, trackingId) => {
  const result = await axios
    .put(API_ENDPOINT + "/" + trackingId, values)
    .then((result) => {
      return {
        success: true,
        message: "El proveedor ha sido actualizado correctamente",
        sent: true,
      };
    })
    .catch((error) => {
      consoleLogger(error.response);

      return {
        success: false,
        message: "Hubo un problema actualizando el registro",
        sent: true,
      };
    });
  return result;
};
const updateProviderType = async (values, trackingId) => {
  const result = await axios
    .put("companyCategories/" + trackingId, values)
    .then((result) => {
      return {
        success: true,
        message: "Tipo de proveedor actualizado correctamente",
        sent: true,
      };
    })
    .catch((error) => {
      consoleLogger(error.response);

      return {
        success: false,
        message: "Hubo un problema actualizando el registro",
        sent: true,
      };
    });
  return result;
};

export default {
  create,
  update,
  getAll,
  getAllCompanyCategories,
  getAllCompanyCategoriesPaginated,
  createProviderType,
  updateProviderType,
  getAllPaginated,
  getCountries,
};
