import { axios } from "../../helpers/AxiosInstance";
import { consoleLogger } from "../../helpers/ConsoleLogger";

const API_ENDPOINT = "packageTracking";

const create = async (status) => {
  const result = await axios
    .post(API_ENDPOINT, status)
    .then((data) => {
      consoleLogger(data);

      return {
        success: true,
        message:
          "El número de rastreo " +
          data.data.data.tracking_number +
          " ha sido creado con éxito",
        sent: true,
      };
    })
    .catch((error) => {
      consoleLogger(error);
      return {
        success: false,
        message: "Parece que hubo un problema registrando el número de rastreo",
        sent: true,
      };
    });
  return result;
};

const getAll = async (paginationData) => {
  try {
    const result = await axios.get(API_ENDPOINT, {
      params: {
        page: paginationData.page,
        limit: parseInt(paginationData.limit),
        search: paginationData.search
      },
    });
    consoleLogger(result);

    return result.data;
  } catch (error) {
    consoleLogger(error);
  }
};

const update = async (values, trackingId) => {
  const result = await axios
    .put(API_ENDPOINT + "/" + trackingId, values)
    .then((result) => {
      return {
        success: true,
        message:
          "Número de rastreo " +
          result.data.data.tracking_number +
          " ha sido actualizado correctamente",
        sent: true,
      };
    })
    .catch((error) => {
      consoleLogger(error.response);

      return {
        success: false,
        message: "Hubo un problema actualizando su número de rastreo",
        sent: true,
      };
    });
  return result;
};

export default {
  create,
  update,
  getAll,
};
