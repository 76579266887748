import React from "react";
import { AppBar, Paper, Tab, Tabs, withStyles } from "@material-ui/core";

const TabsRenderer = ({ selectedTab, handleChange, tabs }) => {
  return (
    <>

      <div className="row  m-auto pb-3">
        <Paper>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {tabs.map((tab) => {
              return <Tab label={tab} />;
            })}
          </Tabs>
        </Paper>
      </div>
    </>
  );
};

export default TabsRenderer;
