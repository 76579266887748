import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import "../assets/scss/sb-admin-2.scss";
import LoginForm from "../components/Forms/Client/LoginForm";
import Logo from './../assets/images/logos/logo-impro.png';
import LogoTransparent from './../assets/images/logos/logo-transparente.png';
import Background from './../assets/images/backgrounds/background-login.jpg';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import Spinner from "../components/Spinner/Spinner";

const StyledDiv = styled.div`
  height: 100vh;
  position: relative;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  .fullheight {
    height: 100vh;
  }
  .bg-login-image {
    background: url(${LogoTransparent});
    background-position: center;
    background-size: cover;
  }
  .bg-background {
    z-index: -2;

    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #1f3075;
      background: -webkit-linear-gradient(top, #1f3075, #4a5daa);
      background: -o-linear-gradient(top, #1f3075, #4a5daa);
      background: -moz-linear-gradient(top, #1f3075, #4a5daa);
      background: linear-gradient(top, #1f3075, #4a5daa);
      opacity: 0.8;
    }
    background-image: url(${Background});
    background-position: center;
    background-size: cover;
    .container {
      z-index: 9999;
    }
  }
  .image-logo {
    height: auto;
    width: 100%;
  }
`;
const LoginPage = ({isAuthenticated, loading}) => {
  debugger;
  if(loading){
    return <Spinner></Spinner>
  }
  if(isAuthenticated){
    return <Redirect to="/admin/rastreo"></Redirect>
  }
  return (
    <>
      <Helmet>
        <title>Inicio de sesión</title>
        <meta charSet="utf-8" />
        <script src="/vendor/jquery-easing/jquery.easing.min.js" />
        <script src="/vendor/bootstrap/js/bootstrap.bundle.min.js" />
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.15.1/css/all.css"
          integrity="sha384-vp86vTRFVJgpjF9jiIGPEEqYqlDwgyBgEF109VFjmqGmIY/Y4HV4d3Gp2irVfcrp"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i"
          rel="stylesheet"
        />
      </Helmet>
      <StyledDiv className="sb-admin">
        <div className="bg-background fullheight  d-flex">
          <div className="container align-middle">
            <div className="row justify-content-center align-middle">
              <div className="col-xl-10 col-lg-12 col-md-9">
                <div className="card o-hidden border-0  my-5">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                      <div className="col-lg-6">
                        <div className="p-5">
                          <div className="text-center justify-content-center">
                            <div className="d-lg-none d-block mb-3">
                              <img
                                className="image-logo"
                                src={Logo}
                              />
                            </div>
                            <h1 className="h2 d-lg-block d-none text-gray-900 mb-4">
                              Industrias Impro
                            </h1>
                          </div>
                          <LoginForm></LoginForm>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledDiv>
    </>
  );
};


LoginForm.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, null)(LoginPage);
