import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Redirect, useLocation } from "react-router-dom";
import Spinner from "../components/Spinner/Spinner";
import PropTypes from "prop-types";
import { isAuth } from "../actions/auth";
import { withRouter } from "react-router-dom";

function PrivateSupportRoute({
  isAuthenticated,
  loading,
  component: Component,
  children,
  isAuth,
  layout: Layout,
  history,
  ...rest
}) {
  const location = useLocation();

  useEffect(() => {
    isAuth();
  }, [location]);
  return (
    <Route
      {...rest}
      render={(matchProps) =>
        !loading ? (
          isAuthenticated ? (
            <Layout>
              <Component {...matchProps} />
            </Layout>
          ) : (
            <Redirect to="/login" />
          )
        ) : (
          <Spinner></Spinner>
        )
      }
    />
  );
}

PrivateSupportRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  loading: PropTypes.bool,
  isAuth: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, { isAuth })(PrivateSupportRoute);
